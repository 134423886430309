import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import styled from 'styled-components'
import CustomTooltip from '../../components/CustomTooltip'

export const ButtonContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  text-transform: none;
`

export const TodayButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 12,
  },
})(props => <Button variant="text" color="primary" {...props} />)

export const MissingButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 12,
  },
})(props => (
  <CustomTooltip title="Go to earliest missing timesheet">
    <Button variant="text" color="secondary" {...props} />
  </CustomTooltip>
))

export const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999999;
`

export const CalendarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px;
`

export const CalendarWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  width: 100%;
  flex: 1;
`

export const StyledControlsContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const GeneralButton = styled(Button)`
  ${({ clickable = true }) => `
    cursor: ${clickable ? 'pointer' : 'default'};
  `}

  font-size: 0.8125rem;
  width: 100%;
  padding: 5px 15px;
  border-radius: 2px;
  background-color: ${({ bgColor }) => bgColor || 'rgb(88, 86, 255)'};
  color: ${({ color }) => color || '#ffffff'};
  height: fit-content;

  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor || 'rgb(88, 86, 255)'};
    color: ${({ hoverColor }) => hoverColor || '#ffffff'};
  }
`
