/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { SubmissionToolbarContainer, ContainerRow, ContainerCol } from './styles'
import TimesheetInfoItem from './TimesheetInfoItem'
import eventUtils from '../../../utils/eventUtils'
import SubmissionToolbar from './SubmissionToolbar'
import { Small } from '../../../styledComponents/text'
import { userSettingsPropTypes } from '../../../constants/userSettings'

import { mapStateToProps } from './store'
import { isEntryStartDateEqualToDate } from '../utils'
import NewEntryBox from '../NewEntryBox'
import { CREATION_WAY } from '../../../constants/timeEntry'

import { getEntry, isAnOperation } from '../../../models/entryOperation'
import { buildNewEntryEvent } from '../../../utils/appUtils'
import { EntryTimelineViewContainer } from '../ListView/EntryBoxEditStyle'
import ViewSwitcher from './ViewSwitcher'

class Header extends React.Component {
  renderTotals = events => {
    const groupedByProjectEvents = eventUtils.groupEventsByProjectType(events, this.props.projects)
    const eventsWithInternalProjects = _.get(groupedByProjectEvents, 'Internal', [])
    const eventsWithPTOCodeProjects = _.get(groupedByProjectEvents, 'PTO', [])
    const eventsWithOtherProjects = _.get(groupedByProjectEvents, 'Project', [])
    const totalsTitle = this.props.isDayViewPeriodSelected ? 'Timesheet total' : 'Week total'

    return (
      <Small>
        <TimesheetInfoItem events={events} title={totalsTitle} showZero />
        {!_.isEmpty(groupedByProjectEvents) && ' | '}
        <TimesheetInfoItem events={eventsWithOtherProjects} title="Project" />
        <TimesheetInfoItem events={eventsWithInternalProjects} title="Internal" />
        <TimesheetInfoItem events={eventsWithPTOCodeProjects} title="PTO" />
      </Small>
    )
  }

  handleCreateEntrySubmission = async (entrySubmission, creationWay) => {
    const entryOrOperation = entrySubmission || this.props.currentEditableEntry
    const isOperation = isAnOperation(entryOrOperation)
    const entry = isOperation ? getEntry(entryOrOperation) : entryOrOperation
    const newEntry = buildNewEntryEvent(entry)
    this.props.handleSafeCreateEntry({ ...newEntry, creationWay })
  }

  renderTimesheetTotals() {
    const { timeEntries, timeOffEntries, selectedDate } = this.props
    const events = _.concat(timeEntries, timeOffEntries)
    if (this.props.isDayViewPeriodSelected) {
      const dayEvents = eventUtils.getEventsBySelectedDate(events, selectedDate)
      const filteredEvents = dayEvents.filter(event =>
        isEntryStartDateEqualToDate(event, this.props.selectedDate),
      )
      return this.renderTotals(filteredEvents)
    }
    const weekEvents = eventUtils.getSelectedWeekEventsByDate(events, selectedDate)
    return this.renderTotals(weekEvents)
  }

  render() {
    return (
      <ContainerCol style={{ width: '100%' }}>
        <ContainerRow style={{ width: '100%' }}>
          <EntryTimelineViewContainer isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}>
            <NewEntryBox
              entry={this.props.currentEditableEntry}
              initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
              projects={this.props.projects}
              handleUpdateEntry={this.props.handleUpdateEntry}
              mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
              handleFavoriteProjectSelection={this.props.handleFavoriteProjectsSelection}
              handleSetDefaultProject={this.props.handleSetDefaultProject}
              handleClearDefaultProject={this.props.handleClearDefaultProject}
              onEntrySubmit={entry =>
                this.handleCreateEntrySubmission(entry, CREATION_WAY.TIMELINE)
              }
              onEntryUpdate={this.props.onEntryUpdate}
              onEntryDelete={this.props.onEntryDelete}
              clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
              clearCurrentEditableEntryProject={this.props.clearCurrentEditableEntryProject}
              isTimeLineView
              isFetchingTimesheetData={this.props.isFetchingTimesheetData}
              userSettings={this.props.userSettings}
              assignments={this.props.assignments}
              projectSelectorRef={this.props.projectSelectorRef}
              person={this.props.person}
            />
          </EntryTimelineViewContainer>
        </ContainerRow>
        <ContainerRow
          style={{ justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }}
        >
          <ViewSwitcher
            classes={this.props.classes}
            setViewPeriodDay={this.props.setViewPeriodDay}
            isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
            view={this.props.view}
            handleTogglePageView={this.props.handleTogglePageView}
            setViewPeriodWeek={this.props.setViewPeriodWeek}
          />
          <SubmissionToolbarContainer style={{ marginLeft: '10px' }}>
            <SubmissionToolbar
              localEntries={this.props.localEntries}
              isConnected={this.props.isConnected}
              isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
              timesheet={this.props.selectedTimesheet}
              isSubmitInProgress={this.props.isSubmitInProgress}
              handleTimesheetSubmission={this.props.handleTimesheetSubmission}
              saveLocalEntryOperations={this.props.saveLocalEntryOperations}
              tabIndex="-1"
              hasTimesheetUnassignedEntries={this.props.hasTimesheetUnassignedEntries}
              hasWeekTimesheetsUnassignedEntries={this.props.hasWeekTimesheetsUnassignedEntries}
              areAllTimesheetsSubmitted={this.props.areAllTimesheetsSubmitted}
              areAllTimesheetsWithEntries={this.props.areAllTimesheetsWithEntries}
              areAllTimesheetsWithoutEntries={this.props.areAllTimesheetsWithoutEntries}
              uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
              isFetchingTimesheetData={this.props.isFetchingTimesheetData}
              person={this.props.person}
              delegateAccessList={this.props.delegateAccessList}
              selectedDelegateId={this.props.selectedDelegateId}
            />
          </SubmissionToolbarContainer>
        </ContainerRow>
      </ContainerCol>
    )
  }
}

Header.propTypes = {
  selectedDelegateId: PropTypes.string,
  assignments: PropTypes.array.isRequired,
  handleSelectedDelegateId: PropTypes.func,
  delegateAccessList: PropTypes.array.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  person: PropTypes.object.isRequired,
  selectedTimesheet: PropTypes.object.isRequired,
  handleTimesheetSubmission: PropTypes.func.isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  handleTogglePageView: PropTypes.func.isRequired,
  changeViewIfNeeded: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  areAllTimesheetsSubmitted: PropTypes.bool.isRequired,
  areAllTimesheetsWithEntries: PropTypes.bool.isRequired,
  areAllTimesheetsWithoutEntries: PropTypes.bool.isRequired,
  isTimesheetLoading: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  setViewPeriodWeek: PropTypes.func.isRequired,
  timeEntries: PropTypes.array.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  saveLocalEntryOperations: PropTypes.func.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
  timeOffEntries: PropTypes.array.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  userSettings: userSettingsPropTypes,
}

export default connect(mapStateToProps)(Header)
