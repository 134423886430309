import moment from 'moment'

export const getLastMonthAndCurrentRanges = () => {
  const today = moment.utc()
  const thisMonthStart = moment.utc(today).startOf('month')
  const thisMonthEnd = moment.utc(today).endOf('month')
  const lastMonthStart = moment
    .utc(thisMonthStart)
    .subtract(1, 'month')
    .startOf('month')
  const lastMonthEnd = moment.utc(lastMonthStart).endOf('month')

  return {
    lastMonth: { start: lastMonthStart.toDate(), end: lastMonthEnd.toDate() },
    thisMonth: { start: thisMonthStart.toDate(), end: thisMonthEnd.toDate() },
  }
}

export const groupTimeEntriesByProjectAndPerson = (timeEntries, timesheets) => {
  const timesheetsMap = timesheets.reduce((acc, timesheet) => {
    acc[timesheet.repliconId] = timesheet.approvalStatus === 'Approved'
    return acc
  }, {})

  const groupedTimeEntries = timeEntries.reduce((acc, entry) => {
    if (!timesheetsMap[entry.repliconTimesheetId]) return acc

    const projectKey = entry.projectId
    if (!acc[projectKey]) {
      acc[projectKey] = []
    }
    const personEntry = acc[projectKey].find(personEntry => personEntry.personId === entry.personId)
    if (!personEntry) {
      acc[projectKey].push({ personId: entry.personId, timeEntries: [entry] })
    } else {
      personEntry.timeEntries.push(entry)
    }
    return acc
  }, {})
  return groupedTimeEntries
}
