import { useCallback, useEffect, useReducer } from 'react'
import moment from 'moment'
import Project from '../../server/models/project'
import { getTimeEntriesByProject } from '../../entities/timeEntry/service'
import { groupTimeEntriesByProjectAndPerson } from './Approvals.utils'
import * as peopleService from '../../entities/people/service'
import Assignment from '../../server/models/assignment'
import { getAllTimesheets } from '../../entities/timesheet/service'
import { fetchAssignmentsByProjectIdsInDateRange } from '../../entities/assignment/service'

const initialState = {
  people: [],
  projects: [],
  timesheets: {
    submitted: [],
    open: [],
  },
  assignments: [],
  entries: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PEOPLE':
      return { ...state, people: action.payload }
    case 'SET_PROJECTS':
      return { ...state, projects: action.payload }
    case 'SET_TIMESHEETS':
      return { ...state, timesheets: action.payload }
    case 'SET_ASSIGNMENTS':
      return { ...state, assignments: action.payload }
    case 'SET_ENTRIES':
      return { ...state, entries: action.payload }
    default:
      return state
  }
}

const getLeadingProjects = async () => {
  const userAssignments = await Assignment.toArray()
  const leadingAssignments = userAssignments.filter(assignment => assignment.type !== 0)
  const allProjects = await Project.toArray()
  return allProjects.filter(project => {
    const notFixedBill = project.isFixedBill === 'false'

    return leadingAssignments.some(
      assignment => assignment.projectId === project._id && notFixedBill,
    )
  })
}

const useApprovalsData = ({ client }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    getLeadingProjects().then(leadingProjects => {
      dispatch({ type: 'SET_PROJECTS', payload: leadingProjects })
    })
  }, [])

  useEffect(() => {
    if (!state.projects.length) return
    const projectIds = state.projects.map(project => project._id).join(',')

    const fetchAssignments = async () => {
      const projectAssignments = await fetchAssignmentsByProjectIdsInDateRange({
        projectIds,
        client,
        endISO: new Date().toISOString(),
        startISO: new Date(0).toISOString(),
      })

      const uniquePeopleIds = new Set(projectAssignments.map(assignment => assignment.personId))
      const peopleData = await peopleService.getAllPeople(client, {
        peopleIds: [...uniquePeopleIds],
      })

      const peopleHash = peopleData.reduce((acc, personData) => {
        acc[personData._id] = personData
        return acc
      }, {})

      dispatch({ type: 'SET_PEOPLE', payload: peopleHash })
      dispatch({ type: 'SET_ASSIGNMENTS', payload: projectAssignments })
    }
    fetchAssignments()
  }, [state.projects])
  useEffect(() => {
    if (!state.projects.length || !Object.values(state.people).length) return

    const getTimeSheets = async () => {
      const moment2MonthsAgo = moment().subtract(2, 'months')
      const endDate = moment().toISOString()

      const timesheets = await getAllTimesheets({
        variables: {
          personIds: Object.keys(state.people),
          startDate: moment2MonthsAgo.startOf('month').toISOString(),
          endDate,
        },
        client,
      })

      const submitted = timesheets.filter(ts => ts.approvalStatus === 'Approved')
      const openTimesheets = timesheets.filter(ts => ts.approvalStatus !== 'Approved')
      const pendingTimeEntries = await getTimeEntriesByProject({
        projectId: state.projects.map(project => project._id).join(','),
        approveState: 'pending',
        client,
      })

      const pendingTimesheetsWithEntries = submitted.map(timesheet => {
        const entries = pendingTimeEntries.filter(
          entry => entry.repliconTimesheetId === timesheet.repliconId,
        )
        return { ...timesheet, entries }
      })

      const groupedPendingTimeEntries = groupTimeEntriesByProjectAndPerson(
        pendingTimeEntries,
        submitted,
      )

      dispatch({
        type: 'SET_ENTRIES',
        payload: groupedPendingTimeEntries,
      })

      dispatch({
        type: 'SET_TIMESHEETS',
        payload: { submitted: pendingTimesheetsWithEntries, open: openTimesheets },
      })
    }
    getTimeSheets()
  }, [state.projects, state.people])

  const onEntryUpdate = useCallback(
    entry => {
      const projectEntries = state.entries[entry.projectId]

      const updatedEntries = projectEntries.map(projectData => {
        if (entry.personId !== projectData.personId) return projectData
        const updatedTimeEntries = projectData.timeEntries.filter(projectEntry => {
          return projectEntry._id !== entry._id
        })

        return { ...projectData, timeEntries: updatedTimeEntries }
      })
      dispatch({
        type: 'SET_ENTRIES',
        payload: {
          ...state.entries,
          [entry.projectId]: updatedEntries,
        },
      })
    },
    [state.entries],
  )

  const onUpdateManyEntries = useCallback(
    entries => {
      const updatedEntries = { ...state.entries }

      const entriesIds = entries.map(entry => entry._id)
      const [firstEntry] = entries
      const projectEntries = state.entries[firstEntry.projectId]
      const updatedProjectEntries = projectEntries.map(projectData => {
        if (firstEntry.personId !== projectData.personId) return projectData

        const updatedTimeEntries = projectData.timeEntries.filter(projectEntry => {
          return !entriesIds.includes(projectEntry._id)
        })

        return { ...projectData, timeEntries: updatedTimeEntries }
      })
      updatedEntries[firstEntry.projectId] = updatedProjectEntries

      dispatch({
        type: 'SET_ENTRIES',
        payload: updatedEntries,
      })
    },
    [state.entries],
  )

  return { state, actions: { onEntryUpdate, onUpdateManyEntries } }
}

export default useApprovalsData
