import gql from 'graphql-tag'

export const UPDATE_TIMESHEET_APPROVAL_STATUS = gql`
  mutation($repliconId: String!, $approvalStatus: String!) {
    updateApprovalStatus(repliconId: $repliconId, approvalStatus: $approvalStatus) {
      _id
      deleted
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const UPDATE_TIMESHEET_APPROVAL_STATUS_AS_DELEGATE = gql`
  mutation($repliconId: String!, $approvalStatus: String!, $delegateId: String!) {
    updateApprovalStatus_asDelegate(
      repliconId: $repliconId
      approvalStatus: $approvalStatus
      delegateId: $delegateId
    ) {
      _id
      deleted
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const UPDATE_TIMESHEET_APPROVE_STATE = gql`
  mutation updateApproveState(
    $status: String!
    $repliconId: String!
    $rejectionReason: String
    $modifiedBy: String
  ) {
    updateApproveState(
      status: $status
      repliconId: $repliconId
      rejectionReason: $rejectionReason
      modifiedBy: $modifiedBy
    ) {
      _id
      repliconId
      approvalStatus
      approveState {
        status
        rejectionReason
        modifiedBy
      }
      totalHours
      endDate
      startDate
      repliconPersonId
      personId
    }
  }
`
