import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import withSizes from 'react-sizes'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Container } from '@material-ui/core'

import timeUtils from '../../utils/timeUtils'
import CalendarHeader from './Header'
import eventUtils from '../../utils/eventUtils'

import {
  buildNewEntryEvent,
  buildUpdatedEntry,
  buildUpdatedEntryEvent,
  buildDroppedUpdatedEntry,
} from '../../utils/appUtils'
import { getDurationInHours } from '../../server/utils/date'

import TimelineView from './TimelineView'
import ListView from './ListView'
import TableView from './TableView'
import CalendarProgressBar from './CalendarProgressBar'

import EventListContainer from './events/EventListContainer'

import { TIMESHEET_VIEWS } from '../../constants/glimpseViews'
import { FUNNEL_ENTRIES_RESOURCE_ID } from '../../constants/events'
import { CREATION_WAY } from '../../constants/timeEntry'

import { APPROVED, OPEN } from '../../constants/timesheetStatus'
import { breakpoints } from '../../constants/breakpoints'
import { userSettingsPropTypes } from '../../constants/userSettings'

import {
  isAnOperation,
  isADeleteOperation,
  getEntry,
  getEntryOrOperation,
} from '../../models/entryOperation'

import { isEntryStartDateEqualToDate } from './utils'
import { getProject } from '../../App/utils'
import { LoadingIndicator, CalendarContent, CalendarWrapper } from './style'
import SidebarControls from './SidebarControls'
import AppNavigation from '../../components/AppNavigation'
import RejectedTable from './RejectedTable'

class Calendar extends PureComponent {
  state = {
    isEmailSelected: false,
    currentSelectedEvent: null,
    eventListIndex: 0,
  }

  showEntryInvoicedModal = () => {
    this.props.showModal({
      message: `This entry has been assigned to an invoice in Stacks. \n To edit, request your Invoicing Manager to unassign the entry.`,
      title: 'LOCKED ENTRY',
    })
  }

  showEntrySubmittedModal = () => {
    this.props.showModal({
      message: `This entry belongs to a day that has been submitted`,
      title: 'LOCKED ENTRY',
    })
  }

  _handleWeekNavigate = amount => {
    const now = new Date()
    const next = timeUtils.addWeeks(this.props.selectedDate, amount)
    if (timeUtils.areSameWeek(now, next)) return this.props.handleCalendarSelect(now)
    this.props.handleCalendarSelect(next)
  }

  _handleDayNavigate = amount => {
    const now = new Date()
    const next = timeUtils.addDays(this.props.selectedDate, amount)

    if (timeUtils.areSameDay(now, next)) return this.props.handleCalendarSelect(now)
    this.props.handleCalendarSelect(next)
  }

  _handleCalendarNavigate = amount => {
    if (this.props.isDayViewPeriodSelected) return this._handleDayNavigate(amount)
    return this._handleWeekNavigate(amount)
  }

  _handlePreFillCurrentEditableEntry = event => {
    if (_.isEmpty(this.props.currentEditableEntry)) return
    const durationInHours = getDurationInHours(event.start, event.end)
    this.props.handleUpdateCurrentEditableEntry({
      start: event.start,
      end: event.end,
      durationInHours,
      originalDurationInHours: durationInHours,
      comment: event.subject,
      originalComment: event.subject,
      referenceId: event.id,
      referenceType: event.referenceType,
      eventTagId: event.tagId,
    })
  }

  handleCreateEntryFromExchange = ({ event, start, end }) => {
    const project = getProject({
      projects: this.props.projects,
      projectId: event.projectId,
    })
    this.onTimeEntrySlotClick(
      {
        start,
        end,
        originalComment: event.subject,
        originalProjectId: _.get(project, '_id', null),
        resourceId: FUNNEL_ENTRIES_RESOURCE_ID,
        referenceId: event.id,
        referenceType: event.referenceType,
        eventTagId: event.tagId,
      },
      event.subject,
    )
  }

  onTimelineEventResize = async eventProps => {
    if (this.isCannotUpdateEvent(eventProps)) return

    const { start, end } = eventProps
    const isSame = moment(start).isSame(end)
    if (isSame) return

    if (eventUtils.isPlaceholderEntry(eventProps.event)) {
      this.props.setCurrentEditableEntry({
        ...eventProps.event,
        start,
        end,
        originalDurationInHours: getDurationInHours(start, end),
      })
      this.props.focusProjectSelector()
      return
    }

    const entrySubmission = buildUpdatedEntry(eventProps)
    await this.props.handleSafeUpdateEntry(entrySubmission)
    this.props.focusProjectSelector()
  }

  onTimelineEventDrop = async (eventProps, timesheets) => {
    if (this.isCannotUpdateEvent(eventProps)) return

    if (eventUtils.isCalendarOrEmailEvent(eventProps.event)) {
      this.handleCreateEntryFromExchange(eventProps)
      return
    }

    if (eventUtils.isPlaceholderEntry(eventProps.event)) {
      const { start, end } = eventProps
      this.props.setCurrentEditableEntry({
        ...eventProps.event,
        start,
        end,
        originalDurationInHours: getDurationInHours(start, end),
      })
      this.props.focusProjectSelector()
      return
    }

    const entrySubmission = buildDroppedUpdatedEntry(eventProps, timesheets)
    await this.props.handleSafeUpdateEntry(entrySubmission)
    this.props.focusProjectSelector()
  }

  handleCreateEntrySubmission = async (entrySubmission, creationWay) => {
    const entryOrOperation = entrySubmission || this.props.currentEditableEntry
    const isOperation = isAnOperation(entryOrOperation)
    const entry = isOperation ? getEntry(entryOrOperation) : entryOrOperation
    const newEntry = buildNewEntryEvent(entry)
    this.props.handleSafeCreateEntry({ ...newEntry, creationWay })
  }

  handleUpdateEntrySubmission = async editableEntry => {
    const currentEditableEntry = editableEntry || this.props.currentEditableEntry
    const isOperation = isAnOperation(currentEditableEntry)
    const entrySubmission = isOperation ? getEntry(currentEditableEntry) : currentEditableEntry

    const entry = this.props.findEntryById(currentEditableEntry)
    const updatedEntry = buildUpdatedEntryEvent(entrySubmission, entry)

    const update = isOperation
      ? {
          ...currentEditableEntry,
          data: _.omit(updatedEntry, ['data', 'action']),
        }
      : updatedEntry

    await this.props.handleSafeUpdateEntry(update)
  }

  handleEntryDelete = entry => {
    if (eventUtils.isEntryInvoiced(entry)) {
      this.showEntryInvoicedModal()
      return
    }
    if (!this.doesThisEntryBelongToUnsubmittedTimesheet(entry)) {
      return this.showEntrySubmittedModal()
    }
    this.props.handleEntryDelete(entry)
    this.props.clearCurrentEditableEntry()

    this.props.focusProjectSelector()
  }

  onSelectEntryToEdit = selectedEntry => {
    if (eventUtils.isTimeOffEvent(selectedEntry)) return
    const isOperation = isAnOperation(selectedEntry)
    if (isOperation && isADeleteOperation(selectedEntry)) return
    if (eventUtils.isEntryInvoiced(selectedEntry)) {
      this.showEntryInvoicedModal()
      return
    }
    // Prevent to create new event into the right column
    if (eventUtils.isEventFromRightCalendarColumn(selectedEntry.resourceId)) {
      return
    }
    if (!this.doesThisEntryBelongToUnsubmittedTimesheet(selectedEntry)) {
      return this.showEntrySubmittedModal()
    }
    this.props.handleSetCurrentEditableEntry(selectedEntry, () => this.props.focusProjectSelector())
  }

  onTimeEntrySlotClick = (selectedSlot, title = '') => {
    // Prevent to create new event into the right column
    if (eventUtils.isEventFromRightCalendarColumn(selectedSlot.resourceId)) {
      return
    }
    this.props.addCalendarPlaceholderEvent(selectedSlot, title)
    this.props.focusProjectSelector()
  }

  createPlaceholderEntryFromEmptyCard = (start, end) => {
    const createAfterRemoved = () => this.props.addCalendarPlaceholderEvent({ start, end })
    this.props.clearCurrentEditableEntry(createAfterRemoved)
  }

  createPlaceholderEntryFromExchangeCard = event => {
    this.props.addCalendarPlaceholderEvent({
      start: event.start,
      end: event.end,
      originalDurationInHours: getDurationInHours(event.start, event.end),
      originalComment: event.subject,
      referenceId: event.id,
      referenceType: event.referenceType,
      originalProjectId: _.get(event, 'projectId', null),
      eventTagId: event.tagId,
    })
  }

  handleClickEmails = () => {
    this.setState({
      isEmailSelected: true,
    })
  }

  handleClickCalendar = () => {
    this.setState({
      isEmailSelected: false,
    })
  }

  handleTogglePageView = (event, view) => {
    if (!view) return null
    this.props.clearCurrentEditableEntry()
    this.props.handleChangeView(view)
  }

  handleSetPageViewList = () => {
    this.props.handleChangeView(TIMESHEET_VIEWS.LIST)
  }

  getSelectedDayFunnelEntries = () => {
    const timeEntriesEvents = this.props.getTimeEntriesEvents()
    const allEntries = _.concat(timeEntriesEvents, this.props.timeOffEntries)
    return eventUtils.getEventsBySelectedDate(allEntries, this.props.selectedDate)
  }

  duplicateEntry = rawEvent => {
    const event = getEntryOrOperation(rawEvent)
    const todayStartOfDay = moment(event.start).startOf('day')
    const todayEndOfDay = moment(event.start).endOf('day')
    const todayElevenFiftyFour = moment(todayStartOfDay).set({ hour: 23, minute: 54, second: 0 })

    if (
      moment(event.end).isSameOrAfter(todayElevenFiftyFour) &&
      moment(event.end).isSameOrBefore(todayEndOfDay)
    ) {
      this.props.showModal({
        message: 'It is not possible to create a time entry at this time of the day',
        title: 'Duplicate Entry',
      })
      return
    }

    const duplicateStart = event.end
    const oneHourAfterDuplicateStart = timeUtils.addMinutes(event.end, 60)
    const duplicateEnd = moment(oneHourAfterDuplicateStart).isAfter(todayEndOfDay)
      ? new Date(todayEndOfDay)
      : oneHourAfterDuplicateStart

    const entry = this.props.buildCurrentEditableEntry(event)
    const newEntry = {
      ...entry,
      originalDurationInHours: 1,
      start: duplicateStart,
      end: duplicateEnd,
    }
    this.handleCreateEntrySubmission(newEntry, rawEvent.creationWay)
  }

  getContextMenuActions = () => ({
    deleteEntry: this.handleEntryDelete,
    duplicateEntry: this.duplicateEntry,
  })

  isCannotUpdateEvent = ({ resourceId }) => {
    // Prevent to update event into the right column
    return (
      eventUtils.isEventFromRightCalendarColumn(resourceId) ||
      // Prevent to update event when timesheet is locked
      this.props.isTimesheetLocked
    )
  }

  doesThisEntryBelongToUnsubmittedTimesheet = entry => {
    const timesheetsList = this.props.timesheets

    const entryTimesheet = timesheetsList.find(timesheet => {
      return isEntryStartDateEqualToDate(entry, timesheet.startDate)
    })

    if (!entryTimesheet) return true

    return entryTimesheet.approvalStatus === OPEN
  }

  setCurrentSelectedEvent = (eventId, tabIndex = 0) => {
    this.setState({ currentSelectedEvent: eventId, eventListIndex: tabIndex })
  }

  renderTimelineView = () => {
    const { startISO, endISO } = this.props.uiRefreshTimePeriod
    const doesUINeedTimesheet = timeUtils.doesEntityOccurInside({ startISO, endISO })

    const timesheets = this.props.timesheets.filter(timesheet => {
      return doesUINeedTimesheet({
        start: timesheet.startDate,
        end: timesheet.endDate,
      })
    })

    const todayTimesheet = eventUtils.getTimesheetForDay(
      this.props.selectedDate,
      this.props.timesheets,
    )
    const isTimesheetSubmitted = todayTimesheet.approvalStatus === APPROVED

    return (
      <>
        <TimelineView
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          show={this.props.view === TIMESHEET_VIEWS.TIMELINE}
          timeEntries={this.props.timeEntries}
          timeOffEntries={this.props.timeOffEntries}
          localEntries={this.props.localEntries}
          unhandledEntries={this.props.unhandledEntries}
          exchangeEmails={this.props.exchangeEmails}
          exchangeEvents={this.props.exchangeEvents}
          usedExchangeEvents={this.props.usedExchangeEvents}
          projects={this.props.projects}
          timeOffCodes={this.props.timeOffCodes}
          selectedDate={this.props.selectedDate}
          timesheets={timesheets}
          isTimesheetLoading={this.props.isTimesheetLoading}
          color="#45ff00"
          onTimeEntrySlotClick={this.onTimeEntrySlotClick}
          onTimeEntryEventClick={this.onSelectEntryToEdit}
          onTimelineEventResize={this.onTimelineEventResize}
          onTimelineEventDrop={this.onTimelineEventDrop}
          isTimesheetLocked={this.props.isTimesheetLocked}
          isEmailSelected={this.state.isEmailSelected}
          handleSetPageViewList={this.handleSetPageViewList}
          clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
          currentEditableEntry={this.props.currentEditableEntry}
          onEntryDelete={this.handleEntryDelete}
          getContextMenuActions={this.getContextMenuActions}
          addCalendarPlaceholderEvent={this.props.addCalendarPlaceholderEvent}
          onEntrySubmit={entry => this.handleCreateEntrySubmission(entry, CREATION_WAY.TIMELINE)}
          onEntryUpdate={this.handleUpdateEntrySubmission}
          selectedDayEntries={this.getSelectedDayFunnelEntries()}
          isConnected={this.props.isConnected}
          handleNoFreeGapModalOpen={this.props.handleNoFreeGapModalOpen}
          showModal={this.props.showModal}
          isTimesheetSubmitted={isTimesheetSubmitted}
          initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
          setCurrentSelectedEvent={this.setCurrentSelectedEvent}
        >
          {this.props.isDayViewPeriodSelected ? this.renderEmailList(!this.props.isMobile) : null}
        </TimelineView>
      </>
    )
  }

  changeViewIfNeeded = () => {
    if (this.props.view === TIMESHEET_VIEWS.TABLE) {
      this.props.handleChangeView(TIMESHEET_VIEWS.TIMELINE)
    }
  }

  renderCalendarHeader = () => {
    return (
      <Container
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '100%',
          padding: '0 15px',
        }}
      >
        <CalendarHeader
          addCalendarPlaceholderEvent={this.props.addCalendarPlaceholderEvent}
          handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
          hasTimesheetUnassignedEntries={this.props.hasTimesheetUnassignedEntries}
          hasWeekTimesheetsUnassignedEntries={this.props.hasWeekTimesheetsUnassignedEntries}
          areAllTimesheetsSubmitted={this.props.areAllTimesheetsSubmitted}
          handleReloadApp={this.props.handleReloadApp}
          person={this.props.person}
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          view={this.props.view}
          timeOffEntries={this.props.timeOffEntries}
          events={this.props.getTimeEntriesEvents()}
          projects={this.props.getProjectWithPTOCodes()}
          timesheets={this.props.timesheets}
          selectedTimesheet={this.props.selectedTimesheet}
          selectedDate={this.props.selectedDate}
          onNavigate={this._handleCalendarNavigate}
          handleCalendarSelect={this.props.handleCalendarSelect}
          handleTimesheetSubmission={this.props.handleTimesheetSubmission}
          isSubmitInProgress={this.props.isSubmitInProgress}
          setViewPeriodDay={this.props.setViewPeriodDay}
          setViewPeriodWeek={this.props.setViewPeriodWeek}
          handleTogglePageView={this.handleTogglePageView}
          changeViewIfNeeded={this.changeViewIfNeeded}
          handleUpload={this.props.handleUpload}
          initialSync={this.props.initialSync}
          handleInitialSyncRetry={this.props.handleInitialSyncRetry}
          isTimesheetLoading={this.props.isTimesheetLoading}
          areAllTimesheetsWithEntries={this.props.areAllTimesheetsWithEntries}
          areAllTimesheetsWithoutEntries={this.props.areAllTimesheetsWithoutEntries}
          timeEntries={this.props.timeEntries}
          handleSafeCreateEntry={this.props.handleSafeCreateEntry}
          exchangeEvents={this.props.exchangeEvents}
          handleSwitchIsIssueReportModalOpen={this.props.handleSwitchIsIssueReportModalOpen}
          handleSwitchIsDelegateAccessModalOpen={this.props.handleSwitchIsDelegateAccessModalOpen}
          localEntries={this.props.localEntries}
          uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
          saveLocalEntryOperations={this.props.saveLocalEntryOperations}
          isFetchingTimesheetData={this.props.isFetchingTimesheetData}
          currentEditableEntry={this.props.currentEditableEntry}
          reminderSettings={this.props.reminderSettings}
          handleSetReminderSettings={this.props.handleSetReminderSettings}
          delegateAccessList={this.props.delegateAccessList}
          selectedDelegateId={this.props.selectedDelegateId}
          handleSelectedDelegateId={this.props.handleSelectedDelegateId}
          userSettings={this.props.userSettings}
          entry={this.props.currentEditableEntry}
          initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
          handleUpdateEntry={this.props.handleUpdateCurrentEditableEntry}
          mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
          handleFavoriteProjectSelection={this.props.handleFavoriteProjectsSelection}
          handleSetDefaultProject={this.props.handleSetDefaultProject}
          handleClearDefaultProject={this.props.handleClearDefaultProject}
          onEntrySubmit={entry => this.handleCreateEntrySubmission(entry, CREATION_WAY.TIMELINE)}
          onEntryUpdate={this.handleUpdateEntrySubmission}
          onEntryDelete={this.handleEntryDelete}
          clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
          clearCurrentEditableEntryProject={this.props.clearCurrentEditableEntryProject}
          isTimeLineView
          assignments={this.props.assignments}
          projectSelectorRef={this.props.projectSelectorRef}
        />
      </Container>
    )
  }

  renderCalendarProgressBar = () => {
    return (
      <CalendarProgressBar
        view={this.props.view}
        handleCalendarSelect={this.props.handleCalendarSelect}
        selectedDate={this.props.selectedDate}
        events={this.props.getTimeEntriesEvents()}
        timesheets={this.props.timesheets}
        isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
        selectCalendarEvents={this.handleClickCalendar}
        selectEmails={this.handleClickEmails}
        isEmailSelected={this.state.isEmailSelected}
        setViewPeriodDay={this.props.setViewPeriodDay}
        isConnected={this.props.isConnected}
        handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
        timeEntries={this.props.timeEntries}
        localEntries={this.props.localEntries}
        exchangeEvents={this.props.exchangeEvents}
        isFetchingTimesheetData={this.props.isFetchingTimesheetData}
        isSubmitInProgress={this.props.isSubmitInProgress}
        uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
      />
    )
  }

  renderEmailList = (hideArrowIcon = false) => {
    const selectedDateExchangeEvents = eventUtils.getEventsBySelectedDate(
      this.props.exchangeEvents,
      this.props.selectedDate,
    )
    const selectedDateExchangeEmails = eventUtils.getEventsBySelectedDate(
      this.props.exchangeEmails,
      this.props.selectedDate,
    )
    const todayTimesheet = eventUtils.getTimesheetForDay(
      this.props.selectedDate,
      this.props.timesheets,
    )
    const isTimesheetSubmitted = todayTimesheet.approvalStatus === APPROVED
    return (
      <EventListContainer
        entries={this.getSelectedDayFunnelEntries()}
        localEntries={this.props.localEntries}
        calendarEvents={selectedDateExchangeEvents}
        emails={selectedDateExchangeEmails}
        isSubmitedTimesheet={this.props.isTimesheetLocked}
        selectedDate={this.props.selectedDate}
        addToEntry={this._handlePreFillCurrentEditableEntry}
        createEntry={this.createPlaceholderEntryFromExchangeCard}
        usedExchangeEvents={this.props.usedExchangeEvents}
        isConnected={this.props.isConnected}
        handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
        timeEntries={this.props.timeEntries}
        exchangeEvents={this.props.exchangeEvents}
        isSubmitInProgress={this.props.isSubmitInProgress}
        isFetchingTimesheetData={this.props.isFetchingTimesheetData}
        uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
        currentEditableEntry={this.props.currentEditableEntry}
        isTimesheetSubmitted={isTimesheetSubmitted}
        hideArrowIcon={hideArrowIcon}
        currentSelectedEvent={this.state.currentSelectedEvent}
        setCurrentSelectedEvent={this.setCurrentSelectedEvent}
        eventListIndex={this.state.eventListIndex}
        setEventListIndex={tabIndex => this.setState({ eventListIndex: tabIndex })}
      />
    )
  }

  renderListView = () => {
    const todayTimesheet = eventUtils.getTimesheetForDay(
      this.props.selectedDate,
      this.props.timesheets,
    )
    const isTimesheetSubmitted = todayTimesheet.approvalStatus === APPROVED
    return (
      <ListView
        showEmptyEntryBox={!this.props.isTimesheetLocked}
        isSubmitedTimesheet={isTimesheetSubmitted}
        selectedDate={this.props.selectedDate}
        currentEditableEntry={this.props.currentEditableEntry}
        handleUpdateCurrentEditableEntry={this.props.handleUpdateCurrentEditableEntry}
        createPlaceholderEntry={this.createPlaceholderEntryFromEmptyCard}
        selectedDayEntries={this.getSelectedDayFunnelEntries()}
        onSelectEntryToEdit={this.onSelectEntryToEdit}
        projects={this.props.getProjectWithPTOCodes()}
        mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
        userSettings={this.props.userSettings}
        assignments={this.props.assignments}
        handleFavoriteProjectSelection={this.props.handleFavoriteProjectsSelection}
        handleSetDefaultProject={this.props.handleSetDefaultProject}
        handleClearDefaultProject={this.props.handleClearDefaultProject}
        onEntrySubmit={entry => this.handleCreateEntrySubmission(entry, CREATION_WAY.LIST)}
        onEntryUpdate={this.handleUpdateEntrySubmission}
        onEntryDelete={this.handleEntryDelete}
        clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
        clearCurrentEditableEntryProject={this.props.clearCurrentEditableEntryProject}
        getContextMenuActions={this.getContextMenuActions}
        addCalendarPlaceholderEvent={this.props.addCalendarPlaceholderEvent}
        isTimesheetLocked={this.props.isTimesheetLocked}
        isFetchingTimesheetData={this.props.isFetchingTimesheetData}
        isConnected={this.props.isConnected}
        projectSelectorRef={this.props.projectSelectorRef}
        initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
        person={this.props.person}
      >
        {this.renderEmailList(!this.props.isMobile)}
      </ListView>
    )
  }

  viewsMap = key =>
    ({
      [TIMESHEET_VIEWS.TIMELINE]: this.renderTimelineView(),
      [TIMESHEET_VIEWS.LIST]: this.renderListView(),
      [TIMESHEET_VIEWS.REJECTED_ENTRIES]: <RejectedTable client={this.props.client} />,
      [TIMESHEET_VIEWS.TABLE]: (
        <TableView
          uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
          timesheets={this.props.timesheets}
          projects={this.props.getProjectWithPTOCodes()}
          timeEntries={this.props.getTimeEntriesEvents()}
          handleUpdateCurrentEditableEntry={this.props.handleUpdateCurrentEditableEntry}
          currentEditableEntry={this.props.currentEditableEntry}
          selectedDate={this.props.selectedDate}
          addCalendarPlaceholderEvent={this.props.addCalendarPlaceholderEvent}
          onSelectEntryToEdit={this.onSelectEntryToEdit}
          handleCreateEntrySubmission={entry =>
            this.handleCreateEntrySubmission(entry, CREATION_WAY.TABLE)
          }
          handleUpdateEntrySubmission={this.handleUpdateEntrySubmission}
          buildCurrentEditableEntry={this.props.buildCurrentEditableEntry}
          showEntryInvoicedModal={this.showEntryInvoicedModal}
          contextMenuActions={this.getContextMenuActions()}
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          handleNoFreeGapModalOpen={this.props.handleNoFreeGapModalOpen}
          userSettings={this.props.userSettings}
          mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
          assignments={this.props.assignments}
          person={this.props.person}
        >
          {this.props.isDayViewPeriodSelected ? this.renderEmailList(!this.props.isMobile) : null}
        </TableView>
      ),
    }[key])

  render() {
    return (
      <CalendarContent>
        <AppNavigation
          handleCreateEntriesForAllEvents={this.props.handleCreateEntriesForAllEvents}
          hasTimesheetUnassignedEntries={this.props.hasTimesheetUnassignedEntries}
          hasWeekTimesheetsUnassignedEntries={this.props.hasWeekTimesheetsUnassignedEntries}
          handleReloadApp={this.props.handleReloadApp}
          person={this.props.person}
          isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
          timesheets={this.props.timesheets}
          isSubmitInProgress={this.props.isSubmitInProgress}
          handleUpload={this.props.handleUpload}
          timeEntries={this.props.timeEntries}
          exchangeEvents={this.props.exchangeEvents}
          handleSwitchIsIssueReportModalOpen={this.props.handleSwitchIsIssueReportModalOpen}
          handleSwitchIsDelegateAccessModalOpen={this.props.handleSwitchIsDelegateAccessModalOpen}
          localEntries={this.props.localEntries}
          uiRefreshTimePeriod={this.props.uiRefreshTimePeriod}
          isFetchingTimesheetData={this.props.isFetchingTimesheetData}
          reminderSettings={this.props.reminderSettings}
          handleSetReminderSettings={this.props.handleSetReminderSettings}
          delegateAccessList={this.props.delegateAccessList}
          selectedDelegateId={this.props.selectedDelegateId}
          handleSelectedDelegateId={this.props.handleSelectedDelegateId}
          assignments={this.props.assignments}
        />
        <CalendarWrapper>
          <SidebarControls
            person={this.props.person}
            isDayViewPeriodSelected={this.props.isDayViewPeriodSelected}
            view={this.props.view}
            events={this.props.getTimeEntriesEvents()}
            timesheets={this.props.timesheets}
            selectedDate={this.props.selectedDate}
            onNavigate={this._handleCalendarNavigate}
            handleCalendarSelect={this.props.handleCalendarSelect}
            setViewPeriodDay={this.props.setViewPeriodDay}
            setViewPeriodWeek={this.props.setViewPeriodWeek}
            handleTogglePageView={this.handleTogglePageView}
            isTimesheetLoading={this.props.isTimesheetLoading}
            delegateAccessList={this.props.delegateAccessList}
            selectedDelegateId={this.props.selectedDelegateId}
            userSettings={this.props.userSettings}
            timeEntries={this.props.timeEntries}
          />
          <div style={{ flex: 1 }}>
            {this.renderCalendarHeader()}
            <div style={{ flex: 1, height: '100%' }}>
              {this.props.isTimesheetLoading && (
                <LoadingIndicator data-testid="calendar.loading-indicator">
                  <CircularProgress />
                </LoadingIndicator>
              )}
              {this.props.view !== TIMESHEET_VIEWS.REJECTED_ENTRIES &&
                this.renderCalendarProgressBar()}
              {this.viewsMap(this.props.view)}
            </div>
          </div>
        </CalendarWrapper>
      </CalendarContent>
    )
  }
}

Calendar.propTypes = {
  view: PropTypes.string,
  client: PropTypes.object,
  handleChangeView: PropTypes.func,
  selectedDelegateId: PropTypes.string,
  handleSelectedDelegateId: PropTypes.func,
  delegateAccessList: PropTypes.array.isRequired,
  handleCreateEntriesForAllEvents: PropTypes.func.isRequired,
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
  handleTimesheetSubmission: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  handleCalendarSelect: PropTypes.func.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  usedExchangeEvents: PropTypes.object.isRequired,
  getTimeEntriesEvents: PropTypes.func.isRequired,
  handleSafeCreateEntry: PropTypes.func.isRequired,
  handleSafeUpdateEntry: PropTypes.func.isRequired,
  handleEntryDelete: PropTypes.func.isRequired,
  clearCurrentEditableEntry: PropTypes.func.isRequired,
  findEntryById: PropTypes.func.isRequired,
  handleUpdateCurrentEditableEntry: PropTypes.func.isRequired,
  handleSetCurrentEditableEntry: PropTypes.func.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  handleFavoriteProjectsSelection: PropTypes.func.isRequired,
  isTimesheetLocked: PropTypes.bool.isRequired,
  isTimesheetLoading: PropTypes.bool.isRequired,
  userSettings: userSettingsPropTypes,
  handleSetDefaultProject: PropTypes.func.isRequired,
  handleClearDefaultProject: PropTypes.func.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.array.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  timeOffCodes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  timesheets: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  getProjectWithPTOCodes: PropTypes.func.isRequired,
  selectedTimesheet: PropTypes.object.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  handleReloadApp: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleSwitchIsDelegateAccessModalOpen: PropTypes.func.isRequired,
  exchangeEvents: PropTypes.array.isRequired,
  exchangeEmails: PropTypes.array.isRequired,
  initialSync: PropTypes.object.isRequired,
  handleInitialSyncRetry: PropTypes.func.isRequired,
  clearCurrentEditableEntryProject: PropTypes.func.isRequired,
  buildCurrentEditableEntry: PropTypes.func.isRequired,
  areAllTimesheetsSubmitted: PropTypes.bool.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  setViewPeriodWeek: PropTypes.func.isRequired,
  areAllTimesheetsWithEntries: PropTypes.bool.isRequired,
  areAllTimesheetsWithoutEntries: PropTypes.bool.isRequired,
  handleSwitchIsIssueReportModalOpen: PropTypes.func.isRequired,
  timeEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  timeOffEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  unhandledEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  saveLocalEntryOperations: PropTypes.func.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  handleNoFreeGapModalOpen: PropTypes.func.isRequired,
  reminderSettings: PropTypes.shape({
    shouldRemind: PropTypes.bool.isRequired,
    weekDays: PropTypes.shape({
      sunday: PropTypes.bool.isRequired,
      monday: PropTypes.bool.isRequired,
      tuesday: PropTypes.bool.isRequired,
      wednesday: PropTypes.bool.isRequired,
      thursday: PropTypes.bool.isRequired,
      friday: PropTypes.bool.isRequired,
      saturday: PropTypes.bool.isRequired,
    }).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    periodInMinutes: PropTypes.number.isRequired,
  }).isRequired,
  handleSetReminderSettings: PropTypes.func.isRequired,
  assignments: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  projectSelectorRef: PropTypes.object,
  setCurrentEditableEntry: PropTypes.func,
  focusProjectSelector: PropTypes.func,
  initialCurrentEditableEntry: PropTypes.object,
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakpoints.md,
})

export default withSizes(mapSizesToProps)(Calendar)
