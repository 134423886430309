import { GET_ALL_TIMESHEETS, GET_TIMESHEETS, GET_TIMESHEETS_AS_DELEGATE } from './query'
import {
  UPDATE_TIMESHEET_APPROVAL_STATUS,
  UPDATE_TIMESHEET_APPROVAL_STATUS_AS_DELEGATE,
  UPDATE_TIMESHEET_APPROVE_STATE,
} from './mutation'

export const fetchTimesheets = async ({ client, selectedDelegateId, ...args }) => {
  const { startDate, endDate, shouldCreateIfNotExist, onlyApproved, repliconIds } = args

  const { data } = await client.query({
    query: selectedDelegateId ? GET_TIMESHEETS_AS_DELEGATE : GET_TIMESHEETS,
    variables: {
      startDate,
      endDate,
      delegateId: selectedDelegateId,
      shouldCreateIfNotExist: shouldCreateIfNotExist || false,
      status: `${onlyApproved ? 'Approved' : 'Open,Approved'}`,
      repliconIds,
    },
  })
  if (!data) return []
  return selectedDelegateId ? data.getTimesheets_asDelegate : data.getTimesheets
}

export const updateTimesheetApprovalStatus = async ({ client, selectedDelegateId, ...args }) => {
  const { data } = await client.mutate({
    mutation: selectedDelegateId
      ? UPDATE_TIMESHEET_APPROVAL_STATUS_AS_DELEGATE
      : UPDATE_TIMESHEET_APPROVAL_STATUS,
    variables: { delegateId: selectedDelegateId, ...args },
  })

  return selectedDelegateId ? data.updateApprovalStatus_asDelegate : data.updateApprovalStatus
}

export const getAllTimesheets = async ({ client, variables = {} }) => {
  const { data } = await client.query({ query: GET_ALL_TIMESHEETS, variables })
  return data.getAllTimesheets
}

export const updateTimesheetApproveStateService = async ({ client, variables = {} }) => {
  const { data } = await client.mutate({ mutation: UPDATE_TIMESHEET_APPROVE_STATE, variables })
  return data.updateApproveState
}
