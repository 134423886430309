import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFormControl = styled(FormControl)`
  margin: 0;
  min-width: 120px;
`
const StyledInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 1.5rem;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`

const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 1.4rem;
  margin-top: 1rem;
`

export const MonthDropdown = ({ onChange }) => {
  const [week, setWeek] = useState('current')

  const handleChange = event => {
    if (onChange) onChange(event.target.value)
    setWeek(event.target.value)
  }

  return (
    <StyledFormControl>
      <StyledInputLabel id="month-select-label">Select Month</StyledInputLabel>
      <StyledSelect
        labelId="month-select-label"
        id="month-select"
        value={week}
        onChange={handleChange}
      >
        <MenuItem value="previous">Previous Month</MenuItem>
        <MenuItem value="current">Current Month</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  )
}

MonthDropdown.propTypes = {
  onChange: PropTypes.func,
}

export default MonthDropdown
