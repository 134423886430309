import React, { useEffect, useState } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import PropTypes from 'prop-types'
import { useError } from 'react-use'
import UserProfile from '../Calendar/Header/UserProfile'
import { ApprovalsBtn, Container, MenuContainer } from '../Calendar/Header/styles'

import {
  ApprovalsContainer,
  StyledPageContainer,
  StyledToggleButton,
  StyledToggleContainer,
} from './Approvals.styles'
import ProjectApprovals from './ApprovalsView'
import useApprovalsData from './useApprovalsData'
import ProjectsView from './ProjecstView'

const views = {
  approvals: 'approvals',
  projects: 'projects',
}

const ApprovalsPage = ({ person, client }) => {
  const [view, setView] = useState(views.approvals)

  const { state, actions } = useApprovalsData({
    client,
    person,
  })

  const { projects, timesheets, people, assignments } = state
  const { onEntryUpdate, onUpdateManyEntries } = actions
  const hasApprovableProjects = projects?.some(project => {
    return project.isFixedBill === 'false'
  })

  useEffect(() => {
    if (hasApprovableProjects) {
      setView(views.approvals)
    } else {
      setView(views.projects)
    }
  }, [hasApprovableProjects])

  return (
    <StyledPageContainer>
      <Container>
        <UserProfile person={person} />
        <MenuContainer>
          <ApprovalsBtn href="#">Timesheets</ApprovalsBtn>
        </MenuContainer>
      </Container>
      <StyledToggleContainer>
        <ToggleButtonGroup
          value={view}
          exclusive
          style={{ margin: '0 auto' }}
          onChange={(_, selectedView) => {
            if (!selectedView) return
            setView(selectedView)
          }}
        >
          {hasApprovableProjects && (
            <StyledToggleButton value={views.approvals}>My Approvals</StyledToggleButton>
          )}
          <StyledToggleButton value={views.projects}>My Project Dashboard</StyledToggleButton>
        </ToggleButtonGroup>
      </StyledToggleContainer>
      <ApprovalsContainer>
        {view === views.approvals && (
          <ProjectApprovals
            projects={projects}
            client={client}
            onEntryUpdate={onEntryUpdate}
            onUpdateManyEntries={onUpdateManyEntries}
            timeEntries={state.entries}
            people={people}
          />
        )}
        {view === views.projects && (
          <ProjectsView
            projects={projects}
            client={client}
            people={people}
            timesheets={timesheets}
            assignments={assignments}
          />
        )}
      </ApprovalsContainer>
    </StyledPageContainer>
  )
}

ApprovalsPage.propTypes = {
  client: PropTypes.object.isRequired,

  person: PropTypes.object.isRequired,
}

export default ApprovalsPage
