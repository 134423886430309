import ToggleButton from '@material-ui/lab/ToggleButton'

import styled from 'styled-components'
import { AccordionSummary } from '@material-ui/core'

export const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  width: 300px;
`
export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ApprovalsContainer = styled.div`
  margin: 10px 50px;
  display: flex;
  height: 100%;
`

export const StyledToggleButton = styled(ToggleButton)`
  color: ${({ theme }) => theme.colors.gray[100]};
  height: 28px;
  width: 160px;
  text-transform: none;
  font-size: 1rem;
  padding: 4px 15px;
  cursor: pointer;
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.nightshade.light};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.nightshade.dark};
    color: ${({ theme }) => theme.colors.gray[100]};
      //border-color: ${({ theme }) => theme.colors.nightshade.medium};

    &:hover {
      background-color: ${({ theme }) => theme.colors.nightshade.dark};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.nightshade.dark};
      //border-color: ${({ theme }) => theme.colors.nightshade.medium};
  }
`

export const StyledCommentInput = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  &:focus {
    outline: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.nightshade.dark};
  }
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
