import gql from 'graphql-tag'

export const GET_TIMESHEETS = gql`
  query(
    $status: String
    $startDate: String
    $endDate: String
    $shouldCreateIfNotExist: Boolean
    $repliconIds: [String]
  ) {
    getTimesheets(
      status: $status
      startDate: $startDate
      endDate: $endDate
      shouldCreateIfNotExist: $shouldCreateIfNotExist
      repliconIds: $repliconIds
    ) {
      _id
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
      repliconPersonId
      personId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const GET_TIMESHEETS_AS_DELEGATE = gql`
  query(
    $status: String
    $startDate: String
    $endDate: String
    $shouldCreateIfNotExist: Boolean
    $delegateId: String!
  ) {
    getTimesheets_asDelegate(
      status: $status
      startDate: $startDate
      endDate: $endDate
      shouldCreateIfNotExist: $shouldCreateIfNotExist
      delegateId: $delegateId
    ) {
      _id
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const GET_ALL_TIMESHEETS = gql`
  query(
    $status: String
    $startDate: String
    $endDate: String
    $shouldCreateIfNotExist: Boolean
    $repliconIds: [String]
    $approveState: [String]
    $personIds: [String]
  ) {
    getAllTimesheets(
      status: $status
      startDate: $startDate
      endDate: $endDate
      shouldCreateIfNotExist: $shouldCreateIfNotExist
      repliconIds: $repliconIds
      approveState: $approveState
      personIds: $personIds
    ) {
      _id
      repliconId
      approvalStatus
      totalHours
      endDate
      startDate
      personId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`
