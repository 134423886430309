import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'

export const StyledProjectsContainer = styled.section`
  display: flex;
  gap: 4rem;
  width: 100%;
`
export const ProjectsTableContainer = styled(Paper)`
  overflow-y: auto;
  width: 100%;
  ${({ rows = 3 }) => css`
    max-height: ${rows * 90}px;
  `};
`

export const StyledProjectsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: center;
`

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`

export const SubmissionStatusCell = styled(TableCell)`
  background-color: ${({ submissionPercentage, theme }) => {
    if (+submissionPercentage > 97) return theme.colors.kellyGreen.dark
    if (+submissionPercentage > 91) return theme.colors.accentYellow
    return theme.colors.gray[500]
  }};
`

export const ProjectSectionContainer = styled.section`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
`
