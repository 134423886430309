import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import ListOutlinedIcon from '@material-ui/icons/ListOutlined'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'
import React from 'react'
import { Views } from 'react-big-calendar'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import CustomTooltip from '../../../../components/CustomTooltip'
import { TIMESHEET_VIEWS } from '../../../../constants/glimpseViews'
import {
  CustomToggle,
  PeriodSwitcherContainer,
  styles,
  ViewSwitcherContainer,
} from '../CalendarDateSelector/style'
import { ViewSwitcherWrapper } from './styles'

const ViewSwitcher = ({
  classes = {},
  isDayViewPeriodSelected,
  view,
  handleTogglePageView,
  setViewPeriodWeek,
  setViewPeriodDay,
}) => {
  const isWeekPeriodSelected = !isDayViewPeriodSelected
  const isWeekViewDisabled = view === TIMESHEET_VIEWS.LIST

  const handleChange = (event, dayViewPeriod) => {
    if (!dayViewPeriod) return null
    if (dayViewPeriod === Views.DAY) return setViewPeriodDay()
    return setViewPeriodWeek()
  }
  return (
    <ViewSwitcherWrapper>
      <ViewSwitcherContainer style={{ marginLeft: '10px' }}>
        <ToggleButtonGroup
          classes={{ root: classes?.toggleButtonGroup }}
          value={view}
          onChange={handleTogglePageView}
          exclusive
        >
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.TIMELINE}`}
            value={TIMESHEET_VIEWS.TIMELINE}
          >
            <CustomTooltip title="Timeline View">
              <CalendarTodayOutlinedIcon style={{ fontSize: '14px' }} />
            </CustomTooltip>
          </CustomToggle>
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.LIST}`}
            value={TIMESHEET_VIEWS.LIST}
            disabled={isWeekPeriodSelected}
          >
            <CustomTooltip title="List View">
              <ListOutlinedIcon />
            </CustomTooltip>
          </CustomToggle>
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.TABLE}`}
            value={TIMESHEET_VIEWS.TABLE}
          >
            <CustomTooltip title="Table View">
              <TableChartOutlinedIcon />
            </CustomTooltip>
          </CustomToggle>
        </ToggleButtonGroup>
      </ViewSwitcherContainer>

      <PeriodSwitcherContainer style={{ marginRight: '10px' }}>
        <ToggleButtonGroup
          classes={{ root: classes.toggleButtonGroup }}
          value={isDayViewPeriodSelected ? Views.DAY : Views.WEEK}
          onChange={handleChange}
          exclusive
        >
          <CustomToggle data-testid={`dayPeriodTab.${Views.DAY}`} value={Views.DAY}>
            <span>Day</span>
          </CustomToggle>
          <CustomToggle
            data-testid={`dayPeriodTab.${Views.WEEK}`}
            value={Views.WEEK}
            disabled={isWeekViewDisabled}
          >
            <span>Week</span>
          </CustomToggle>
        </ToggleButtonGroup>
      </PeriodSwitcherContainer>
    </ViewSwitcherWrapper>
  )
}

ViewSwitcher.propTypes = {
  classes: PropTypes.object,
  isDayViewPeriodSelected: PropTypes.bool,
  view: PropTypes.string,
  handleTogglePageView: PropTypes.func,
  setViewPeriodWeek: PropTypes.func,
  setViewPeriodDay: PropTypes.func,
}

export default withStyles(styles)(ViewSwitcher)
