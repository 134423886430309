import React, { useCallback, useEffect, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'

import PropTypes from 'prop-types'
import { FormLabel, Radio, RadioGroup } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import styled from 'styled-components'
import {
  ApprovalsPeopleContainer,
  ApprovalViewContainer,
  StyledAccordion,
  StyledApproveButton,
} from './ApprovalView.styles'
import { ProjectsTableContainer, StyledTableHead } from './ProjectsView.styles'
import EntryColumns from './Components/ApprovalsTableColumn'
import { StyledAccordionSummary } from './Approvals.styles'
import { updateTimeEntry } from '../../entities/timeEntry/service'

const ApprovalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledFormLabel = styled(FormLabel)`
  // color: ${({ theme }) => theme.colors.gray[700]};

  &.MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.gray[900]};
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiIconButton-label {
    color: ${({ theme }) => theme.colors.nightshade.medium};
  }
`

document.body.style.overflow = 'auto'
const ProjectSelection = ({ projects, selectedProject, setSelectedProject }) => {
  return (
    <FormControl>
      <StyledFormLabel id="project-radio-group-label">Select Project</StyledFormLabel>
      <RadioGroup
        aria-labelledby="project-radio-group-label"
        name="project-radio-group"
        value={selectedProject}
        onChange={e => setSelectedProject(e.target.value)}
      >
        {projects.map(project => (
          <StyledFormControlLabel
            key={project._id}
            value={project._id}
            control={<Radio />}
            label={project.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const ProjectApprovals = ({
  projects,
  client,
  onEntryUpdate,
  onUpdateManyEntries,
  timeEntries,
  people,
}) => {
  const [selectedProject, setSelectedProject] = useState(projects[0])

  useEffect(() => {
    setSelectedProject(projects[0])
  }, [projects])

  const peopleEntries = timeEntries[selectedProject?._id] || []

  const setProject = id => {
    setSelectedProject(projects.find(project => project._id === id))
  }

  const updateTimeEntryLocal = useCallback(
    async entry => {
      const updatedEntry = {
        ...entry,
        approveState: { status: 'approved' },
      }
      const result = await updateTimeEntry({ client, timeEntry: updatedEntry })
      return result
    },
    [client, onEntryUpdate],
  )

  const [selectedEntries, setSelectedEntries] = useState({})

  const onSelect = useCallback(
    entry => {
      if (
        selectedEntries[entry.personId] &&
        selectedEntries[entry.personId].some(currentEntry => currentEntry._id === entry._id)
      ) {
        setSelectedEntries({
          ...selectedEntries,
          [entry.personId]: selectedEntries[entry.personId].filter(
            personEntry => personEntry._id !== entry._id,
          ),
        })
        return
      }

      setSelectedEntries({
        ...selectedEntries,
        [entry.personId]: [...(selectedEntries[entry.personId] || []), entry],
      })
    },
    [selectedEntries, setSelectedEntries],
  )

  const approveAll = (event, localPersonEntries) => {
    event.stopPropagation()
    const selectedPersonEntries = selectedEntries[localPersonEntries[0].personId] || []
    const entriesToApprove =
      selectedPersonEntries.length > 0 ? selectedPersonEntries : localPersonEntries

    const promises = entriesToApprove.map(async entry => {
      return await updateTimeEntryLocal(entry)
    })

    Promise.all(promises).then(entries => {
      onUpdateManyEntries(entries)
    })
  }

  const peopleEntriesWithAtLeastOnePending = peopleEntries.filter(personEntry => {
    return personEntry.timeEntries.some(entry => entry.approveState.status === 'pending')
  })

  return (
    <ApprovalsContainer>
      <ApprovalViewContainer>
        {selectedProject && (
          <ProjectSelection
            projects={projects}
            selectedProject={selectedProject?._id}
            setSelectedProject={setProject}
          />
        )}
        <ApprovalsPeopleContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {peopleEntriesWithAtLeastOnePending.map(personEntry => {
              const approveText =
                selectedEntries[personEntry.personId]?.length > 0
                  ? 'Approve Selected'
                  : 'Approve All'
              const personData = people[personEntry.personId]
              const entries = personEntry.timeEntries

              return (
                <StyledAccordion key={`${personData.fullName}-${selectedProject?.id}`}>
                  <StyledAccordionSummary>
                    <Typography>{personData.fullName}</Typography>
                    <StyledApproveButton onClick={event => approveAll(event, entries)}>
                      {approveText}
                    </StyledApproveButton>
                  </StyledAccordionSummary>

                  <TableContainer component={ProjectsTableContainer} rows={5}>
                    <Table aria-label="simple table">
                      <StyledTableHead>
                        <TableRow>
                          <TableCell width={20} />
                          <TableCell width={300}>Comment</TableCell>
                          <TableCell width={135}>Hours</TableCell>
                          <TableCell width={135}>Date</TableCell>
                          <TableCell width={135}>Billable</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {entries.map(entry => {
                          const entryKey = `${entry.personId}-${entry.start}-${entry.projectId}`
                          return (
                            <TableRow key={entryKey}>
                              <EntryColumns
                                onEntryUpdate={onEntryUpdate}
                                entry={entry}
                                onSelect={onSelect}
                                client={client}
                              />
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledAccordion>
              )
            })}
          </div>

          <p style={{ width: '80%', maxWidth: '1000px' }}>
            Once a timesheet is approved, the approved timesheets will flow into &nbsp;
            <a href="https://stacks.keystonestrategy.io/" target="_blank" rel="noreferrer">
              Stacks
            </a>
            &nbsp; You must manage your approvals to ensure your project is up to date. Glimpse
            should be a true reflection of actual hours worked by employees. Any write-offs must be
            done in Stacks. Please do not reject time entries in Glimpse for the purposes of write
            offs
          </p>
        </ApprovalsPeopleContainer>
      </ApprovalViewContainer>
    </ApprovalsContainer>
  )
}

ProjectApprovals.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  timesheets: PropTypes.arrayOf(PropTypes.object),
  client: PropTypes.object.isRequired,
}

export default ProjectApprovals
