import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Divider from '@material-ui/core/Divider'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import {
  ProjectSectionContainer,
  ProjectsTableContainer,
  StyledProjectsContainer,
  StyledProjectsContent,
  StyledTableHead,
  SubmissionStatusCell,
} from './ProjectsView.styles'
import { getLastMonthAndCurrentRanges } from './Approvals.utils'
import { ApprovalsBtn } from '../Calendar/Header/styles'
import { MonthDropdown } from './Components/MonthDropdown'

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const useOverviewData = ({ projects, projectsAssignments, month, timesheets }) => {
  const [overviewData, setOverviewData] = useState([])

  useEffect(() => {
    if (!projects.length || !projectsAssignments.length) return

    const overViewRows = projects.map(project => {
      const projectAssignments = projectsAssignments.filter(
        proj => proj.projectId === project._id && proj.type === 0,
      )

      const assigmentPeopleTimesheets = timesheets.submitted.filter(timesheet => {
        const weekStart = moment.utc(timesheet.startDate)
        const startedAfterMonth = weekStart >= month.start
        const startedBeforeEnd = weekStart <= month.end

        return (
          startedAfterMonth &&
          startedBeforeEnd &&
          projectAssignments.some(assignment => assignment.personId === timesheet.personId)
        )
      })

      let elapsedDays = new Date().getDate()

      if (month.end < new Date().getTime()) {
        elapsedDays = getMonthDaysQty(month)
      }

      const expectedTimesheets = projectAssignments.length * elapsedDays

      const submissionStatus = Math.floor(
        (assigmentPeopleTimesheets.length / expectedTimesheets) * 100,
      )

      return {
        projectName: project.name,
        totalStaff: projectAssignments.length,
        submissionStatus: `${submissionStatus}`,
        delinquencies: expectedTimesheets - assigmentPeopleTimesheets.length,
      }
    })

    setOverviewData(overViewRows)
  }, [projects, projectsAssignments, month, timesheets.submitted])

  return overviewData
}

const getMonthDaysQty = month => {
  const start = moment.utc(month.start)
  const end = moment.utc(month.end)
  return end.diff(start, 'days') + 1
}

// Get all M-F days in a month using moment.js
const getWeekDaysInMonth = date => {
  const days = []
  const month = date.clone().startOf('month')
  const end = date.clone().endOf('month')

  while (month.isBefore(end)) {
    if (month.day() !== 0 && month.day() !== 6) {
      days.push(month.clone())
    }
    month.add(1, 'days')
  }

  return days.length
}

const fillMissingDaysArray = (month, missingDatesArray, omitDates) => {
  const datesArray = [...missingDatesArray]
  const monthDays = getMonthDaysQty(month)
  const isCurrentMOnth = moment.utc().isSame(month.start, 'month')

  const fillUntilToday = moment.utc().date()

  if (datesArray.length === monthDays) {
    return datesArray
  }

  for (let i = 1; i <= monthDays; i++) {
    if (i > fillUntilToday && isCurrentMOnth) break
    if (!datesArray.includes(i) && !omitDates.includes(i)) {
      datesArray.push(i)
    }
  }

  return datesArray
}

const useDelinquenciesData = ({ projects, projectsAssignments, month, timesheets, people }) => {
  const [delinquencies, setDelinquenciesData] = useState([])

  useEffect(() => {
    if (!projects.length || !projectsAssignments.length || !Object.keys(people).length) return
    const monthTimesheets = [...timesheets.submitted, ...timesheets.open].filter(ts => {
      const weekStartUtc = moment.utc(month.start)
      const weekEndUtc = moment.utc(month.end)
      const tsWeekStartUtc = moment.utc(ts.startDate)
      return tsWeekStartUtc.isSameOrAfter(weekStartUtc) && tsWeekStartUtc.isSameOrBefore(weekEndUtc)
    })

    const newDelinquencies = projectsAssignments.map(assignment => {
      const assignmentTimesheets = monthTimesheets.filter(timesheet => {
        return timesheet.personId === assignment.personId && timesheet.approvalStatus === 'Approved'
      })
      const workedHours = assignmentTimesheets.reduce((acc, timesheet) => {
        const tsHours = timesheet?.entries.reduce((acc, entry) => {
          if (entry.projectId === assignment.projectId) {
            return acc + entry.durationInHours
          }
          return acc
        }, 0)

        return (tsHours || 0) + acc
      }, 0)

      const deliveredDates = monthTimesheets
        .filter(timesheet => {
          return (
            timesheet.personId === assignment.personId && timesheet.approvalStatus === 'Approved'
          )
        })
        .map(timesheet => {
          return moment.utc(timesheet.startDate).date()
        })

      const rawMissingDates = monthTimesheets
        .filter(timesheet => {
          return (
            timesheet.personId === assignment.personId && timesheet.approvalStatus !== 'Approved'
          )
        })
        .map(timesheet => {
          return moment.utc(timesheet.startDate).date()
        })
      const missingDates = fillMissingDaysArray(month, rawMissingDates, deliveredDates)

      const assigmentPercent = +assignment.percent.replace('%', '') / 100
      const totalMonthDays = getWeekDaysInMonth(moment.utc(month.start)) //

      const expectedHours = Math.ceil(totalMonthDays * assigmentPercent * 8)
      const hoursText = `${workedHours} / ${expectedHours}`
      return {
        personId: assignment?.personId,
        personName: people[assignment?.personId].fullName,
        projectName: projects.find(proj => proj._id === assignment.projectId)?.name,
        missingDays: missingDates.length,
        missingDates: missingDates.sort((a, b) => a - b).join(', '),
        expectedHours: hoursText,
        email: people[assignment.personId].email,
      }
    })

    setDelinquenciesData(newDelinquencies)
  }, [projectsAssignments, projects, month, timesheets, people])

  return delinquencies
}

const ProjectsView = ({ projects = [], people, timesheets, assignments }) => {
  const { lastMonth, thisMonth } = useMemo(() => getLastMonthAndCurrentRanges(), [])

  const [month, setMonth] = useState('current')

  const selectedMonth = month === 'current' ? thisMonth : lastMonth

  const overviewData = useOverviewData({
    projects,
    projectsAssignments: assignments,
    month: selectedMonth,
    timesheets,
    people,
  })

  const delinquenciesData = useDelinquenciesData({
    projects,
    projectsAssignments: assignments,
    month: selectedMonth,
    timesheets,
    people,
  })

  const monthsNames = {
    current: moment().format('MMMM'),
    previous: moment()
      .subtract(1, 'months')
      .format('MMMM'),
  }

  return (
    <StyledPageContainer>
      <StyledProjectsContainer>
        <div>
          <MonthDropdown onChange={val => setMonth(val)} />
        </div>

        <StyledProjectsContent>
          <h1>{monthsNames[month] ?? monthsNames.previous}</h1>
          <ProjectSectionContainer>
            <h3 unselectable>Project overview</h3>
            <TableContainer component={ProjectsTableContainer}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Total Staff</TableCell>
                    <TableCell>Timesheet Submission Status</TableCell>
                    <TableCell>Total Missing Timesheets</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {overviewData.map(row => (
                    <TableRow key={`${row.projectName}`}>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.totalStaff}</TableCell>
                      <SubmissionStatusCell submissionPercentage={row.submissionStatus}>
                        {row.submissionStatus}%
                      </SubmissionStatusCell>
                      <TableCell>{row.delinquencies}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ProjectSectionContainer>

          <Divider />
          <ProjectSectionContainer>
            <div>
              <h3 unselectable>Delinquencies</h3>

              <TableContainer component={ProjectsTableContainer}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Person Name</TableCell>
                      <TableCell>Project Name</TableCell>
                      <TableCell width={135}>Missing Timesheets</TableCell>
                      <TableCell width={135}>Missing Dates</TableCell>
                      <TableCell width={135}>Submitted Hours</TableCell>
                      <TableCell align="center">Nudge</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {delinquenciesData.map(row => {
                      const personName = row.personName || people[row.personId]?.fullName
                      const personEmail = people[row.personId]?.email
                      const monthName = monthsNames[month] ?? monthsNames.previous

                      const emailBody = `You are currently missing ${row.missingDays} timesheets for ${row.projectName}.
                      Missing Dates: ${monthName} ${row.missingDates}`.replace(' ', '%20')
                      const emailSubject = `Missing Timesheets, Please submit ASAP.`.replace(
                        ' ',
                        '%20',
                      )

                      const emailHref = `https://outlook.office.com/mail/deeplink/compose?to=${personEmail}&subject=${emailSubject}&body=${emailBody}`

                      return (
                        <TableRow key={`${personName}-${row.projectName}`}>
                          <TableCell>{personName}</TableCell>
                          <TableCell>{row.projectName}</TableCell>
                          <TableCell align="center">{row.missingDays}</TableCell>
                          <TableCell align="center">{row.missingDates}</TableCell>
                          <TableCell align="center">{row.expectedHours}</TableCell>
                          <TableCell align="center">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <ApprovalsBtn target="_blank" href={emailHref}>
                                Email
                              </ApprovalsBtn>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </ProjectSectionContainer>
        </StyledProjectsContent>
      </StyledProjectsContainer>
    </StyledPageContainer>
  )
}

ProjectsView.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  assignments: PropTypes.arrayOf(PropTypes.object),
  timesheets: PropTypes.arrayOf(PropTypes.object),
  people: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectsView
